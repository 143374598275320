import { getAnalyticsConsent } from 'portal-sdk';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import userflow from 'userflow.js';
import { heapAgent } from 'vet-bones/utils';
import { usePortalCsrfToken } from 'vet-bones/utils/portal';

import { useAppDispatch } from 'src/ui/app/hooks';
import { browserTest } from 'src/ui/constants/browserTest';
import {
  RUNNING_CYPRESS,
  TRACKING_DISABLED,
  USERFLOW_ID,
} from 'src/ui/constants/environment';
import {
  isStardogEmployee,
  loggedinUserEmail,
  returningUser,
  showInitialDialog,
  showUnsupportedBrowserDialog,
  unverifiedUser,
  userId,
  usingUnsupportedBrowser,
} from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { ProfileQuery, useProfileQuery } from 'src/ui/graph/types';
import { getConnectionFromCookie } from 'src/ui/utils/cookies/getConnectionFromCookie';
import { redirectToUrl } from 'src/ui/utils/window';

const setupUserflow = (data: ProfileQuery) => {
  if (!USERFLOW_ID) {
    return;
  }

  userflow.init(USERFLOW_ID);

  const { profile } = data;

  if (!profile || !profile.email) {
    userflow.identifyAnonymous();
    return;
  }

  const {
    id,
    email,
    first_name: firstName,
    last_name: lastName,
    userflow_signature: signature,
    company,
    title,
    use_case: useCase,
    is_databricks_user: isDatabricksUser,
    date_joined: dateJoined,
  } = profile;

  userflow.identify(
    email,
    {
      cloud_user_id: id,
      email,
      name: `${firstName} ${lastName}`,
      company,
      title,
      use_case: useCase,
      is_databricks_user: isDatabricksUser,
      signed_up_at: dateJoined,
    },
    {
      signature: signature ?? undefined,
    }
  );
};

/**
 * Run Application level hooks so we don't force the main App to re-render
 */
export const AppHooks: React.VFC = () => {
  const { data, isLoading } = useProfileQuery(graphQLClient);
  const dispatch = useAppDispatch();
  const consent = getAnalyticsConsent();
  const location = useLocation();
  const isLoginRoute = (location.pathname || '').match(/login/);

  usePortalCsrfToken();

  const connectionCookie = getConnectionFromCookie();
  if (
    !isLoginRoute &&
    connectionCookie.name &&
    !connectionCookie.isLaunchpad &&
    !connectionCookie.isPortal
  ) {
    redirectToUrl('/login');
  }

  React.useEffect(() => {
    if (isLoading || !data || !data.profile) {
      return;
    }

    if (!data.profile.is_authenticated || data.profile.is_ephemeral) {
      return;
    }

    if (!data.profile.is_verified) {
      dispatch(unverifiedUser());
    }

    if (data.profile.email) {
      dispatch(loggedinUserEmail(data.profile.email));
    }

    if (data.profile.email?.endsWith('stardog.com')) {
      dispatch(isStardogEmployee());
    }

    if (data.profile.id) {
      dispatch(userId(data.profile.id));
    }

    // These do not need to run during cypress tests, and they can cause intermittent failures
    if (
      consent &&
      consent.statistics &&
      !RUNNING_CYPRESS &&
      !TRACKING_DISABLED
    ) {
      const email = data?.profile?.email;

      heapAgent.load();

      // If there's no email, then it's an anonymous user (I'm fairly sure this can't happen)
      if (email) {
        heapAgent.identify(email);
      }

      setupUserflow(data);
    }

    if (!browserTest.test(navigator.userAgent)) {
      dispatch(usingUnsupportedBrowser());
    }

    dispatch(showUnsupportedBrowserDialog());

    if (data.profile.has_updated_profile) {
      dispatch(returningUser());
      return;
    }

    dispatch(showInitialDialog());
  }, [data, isLoading, dispatch, consent, isLoginRoute]);

  return null;
};
