import { Button, H2, HTMLTable, InputGroup } from '@blueprintjs/core';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import {
  CONNECTION_LIST_ADD_CONNECTION,
  CONNECTION_LIST_GET_CLOUD,
} from 'src/ui/constants/testIds';
import { ConnectionRow } from 'src/ui/containers/connection-list/ConnectionRow';
import { CancelCloudDialog } from 'src/ui/containers/dialogs/CancelCloudDialog';
import { showAddNewDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { useSetConnectionIndex } from 'src/ui/hooks/connection';
import * as copy from 'src/ui/templates/copy';

interface ConnectionListProps {
  header: string;
  redirectWhenNoConnections: boolean;
}

export const ConnectionList: React.VFC<ConnectionListProps> = ({
  header,
  redirectWhenNoConnections,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const setConnectionIndex = useSetConnectionIndex();
  const { data, isLoading } = useSavedConnectionsQuery(graphQLClient);
  const [connectionFilter, setConnectionFilter] = React.useState<string>('');
  const [connections, setConnections] = React.useState<GraphConnection[]>([]);
  const [isCancelCloudDialogOpen, setIsCancelCloudDialogOpen] = React.useState(
    false
  );
  const [
    cloudConnectionToDelete,
    setCloudConnectionToDelete,
  ] = React.useState<GraphConnection | null>(null);

  React.useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    const changed = data.listConnections || [];
    setConnections(changed as React.SetStateAction<GraphConnection[]>);

    if (changed.length === 0 && redirectWhenNoConnections) {
      history.push('/get-started');
    }
  }, [data, isLoading, redirectWhenNoConnections, history]);

  const goToDashboard = (index: number) => {
    setConnectionIndex(index);
  };

  const handleAdd = () => dispatch(showAddNewDialog());

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setConnectionFilter(evt.target.value);
  };

  const cards = connections
    .filter(
      (c) =>
        !connectionFilter ||
        c.name
          .toLocaleLowerCase()
          .includes(connectionFilter.toLocaleLowerCase())
    )
    .map((conn) => {
      return (
        <ConnectionRow
          connection={conn}
          key={conn.id}
          onClick={() => goToDashboard(conn.index)}
          onCancelCloud={(c) => {
            if (c) {
              setCloudConnectionToDelete(c);
              setIsCancelCloudDialogOpen(true);
            }
          }}
        />
      );
    });

  const getActionButtons = (searchDisabled: boolean) => {
    return (
      <div className="sd-table__actions">
        <div className="sd-table__actions__button-container">
          <Button
            icon="plus"
            onClick={handleAdd}
            intent="primary"
            outlined
            minimal
            data-testid={CONNECTION_LIST_ADD_CONNECTION}
          >
            {copy.components.connectionList.add}
          </Button>
          <Button
            icon="cloud"
            onClick={() => {
              history.push('/get-started');
            }}
            intent="success"
            outlined
            minimal
            data-testid={CONNECTION_LIST_GET_CLOUD}
          >
            {copy.components.connectionList.getCloud}
          </Button>
          <InputGroup
            disabled={searchDisabled}
            type="search"
            data-testid="connection-filter"
            name="connection-filter"
            placeholder={copy.components.connectionList.searchConnections}
            onChange={handleSearchChange}
            leftIcon="search"
          />
        </div>
      </div>
    );
  };

  const body =
    connections.length === 0 ? (
      <div className="no-connection-container">
        <div className="sd-table__header">
          <H2>{header}</H2>
          {getActionButtons(true)}
        </div>
        <hr className="divider" />
        <p>{copy.components.connectionList.noConnections}</p>
      </div>
    ) : (
      <div className="sd-table">
        <div className="sd-table__header">
          <H2>{header}</H2>
          {getActionButtons(false)}
        </div>
        <hr className="divider" />

        <HTMLTable striped interactive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Endpoint URL</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{cards}</tbody>
        </HTMLTable>
      </div>
    );

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {body}
      {cloudConnectionToDelete && (
        <CancelCloudDialog
          connection={cloudConnectionToDelete}
          isOpen={isCancelCloudDialogOpen}
          onClose={() => setIsCancelCloudDialogOpen(false)}
          onSuccess={() => {
            setIsCancelCloudDialogOpen(false);
            // Invalidate the query so that the new connection shows up in the list
            queryClient.invalidateQueries(useSavedConnectionsQuery.getKey());
          }}
        />
      )}
    </>
  );
};
