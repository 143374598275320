import { Icon, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { Connection } from 'src/ui/features/connection';
import { useVerifyConnection } from 'src/ui/hooks/connection';
import { copy } from 'src/ui/templates/copy';

interface OwnProps {
  connection: Connection | undefined | null;
  isLoading: boolean;
  minimal?: boolean;
}

/* istanbul ignore next: too hard to test this right now */
export const DiagnosticCheckStardog: React.VFC<OwnProps> = ({
  connection,
  isLoading,
  minimal = false,
}) => {
  const { isLoading: isValidating, mutate: validate } = useVerifyConnection();
  const [connectionValid, setConnectionValid] = React.useState(false);

  // This effect will run when the `connection` changes or when `isLoading`
  // changes. `isLoading` is set by the diagnostic api call and can be retried
  // by the user, aka after adding the correct user. So we need to re-run
  // this check to see if the user is valid.
  React.useEffect(() => {
    if (!connection) {
      return;
    }

    validate(connection, {
      onError: () => setConnectionValid(false),
      onSuccess: () => setConnectionValid(true),
    });
  }, [connection, isLoading]);

  if (!connection) {
    return null;
  }

  const icon = connectionValid ? IconNames.TICK_CIRCLE : IconNames.ERROR;
  const intent = connectionValid ? Intent.SUCCESS : Intent.DANGER;
  const message = connectionValid ? 'Authorized' : 'Unauthorized';
  const largeMessage = connectionValid
    ? copy.diagnostic.connectionValid
    : copy.diagnostic.connectionInvalid;
  const serverInfo = `Server: ${connection.endpoint} ${message}`;

  if (isValidating) {
    return (
      <div className="diagnostic-check">
        <Spinner size={SpinnerSize.SMALL}>Loading...</Spinner>
      </div>
    );
  }

  // small is used along size all the other checks
  const small = (
    <div className="diagnostic-check">
      <Icon icon={icon} intent={intent} />
      <span>{serverInfo}</span>
    </div>
  );
  // large is used in the header where we have more room to fill
  const large = (
    <div className="diagnostic-message">
      <Icon icon={icon} intent={intent} />
      <span>{largeMessage}</span>
      <a href="https://status.stardog.com">Check our status page</a>
    </div>
  );

  return minimal ? small : large;
};
