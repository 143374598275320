import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import * as React from 'react';

import { AzureLink } from 'src/ui/components/AzureLink';
import { Dialog } from 'src/ui/components/Dialog';
import { GeoaxisLink } from 'src/ui/components/GeoaxisLink';
import { GoogleLink } from 'src/ui/components/GoogleLink';
import { KeycloakLink } from 'src/ui/components/KeycloakLink';
import { Loading } from 'src/ui/components/Loading';
import { OpenIDLink } from 'src/ui/components/OpenIDLink';
import { useAuthenticateMutation } from 'src/ui/hooks/authentication';
import { copy } from 'src/ui/templates/copy';
import {
  isAzureAuthEnabled,
  isGeoaxisAuthEnabled,
  isGoogleAuthEnabled,
  isKeycloakAuthEnabled,
  isOpenIDAuthEnabled,
  isPasswordAuthEnabled,
} from 'src/ui/utils/features';
import { getFriendlyName, redirectToUrl } from 'src/ui/utils/window';

export const Login: React.VFC = () => {
  const { isLoading, mutate } = useAuthenticateMutation();
  const friendlyName = getFriendlyName();
  const [state, setState] = React.useState({
    username: '',
    password: '',
  });
  const [isDialogOpen, setIsDialogOpen] = React.useState(true);

  const handleTextFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const azureAuthEnabled = isAzureAuthEnabled();
  const geoaxisAuthEnabled = isGeoaxisAuthEnabled();
  const googleAuthEnabled = isGoogleAuthEnabled();
  const keycloakAuthEnabled = isKeycloakAuthEnabled();
  const openidAuthEnabled = isOpenIDAuthEnabled();
  const passwordAuthEnabled = isPasswordAuthEnabled();
  const oauthEnabled =
    azureAuthEnabled ||
    geoaxisAuthEnabled ||
    googleAuthEnabled ||
    keycloakAuthEnabled ||
    openidAuthEnabled;
  const separator = oauthEnabled && passwordAuthEnabled ? <p>or</p> : null;
  const azureButton = azureAuthEnabled ? <AzureLink /> : null;
  const geoaxisButton = geoaxisAuthEnabled ? <GeoaxisLink /> : null;
  const googleButton = googleAuthEnabled ? <GoogleLink /> : null;
  const keycloakButton = keycloakAuthEnabled ? <KeycloakLink /> : null;
  const openidButton = openidAuthEnabled ? <OpenIDLink /> : null;
  const passwordAuthForm = passwordAuthEnabled ? (
    <div className={Classes.DIALOG_BODY}>
      <FormGroup label={copy.login.username} labelFor="username">
        <InputGroup
          autoFocus
          id="username"
          name="username"
          autoComplete="username"
          value={state.username}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
      <FormGroup label={copy.login.password} labelFor="password">
        <InputGroup
          name="password"
          id="password"
          type="password"
          autoComplete="password"
          value={state.password}
          onChange={handleTextFieldChange}
        />
      </FormGroup>
    </div>
  ) : null;
  const passwordAuthConnectButton = passwordAuthEnabled ? (
    <Button
      intent={Intent.PRIMARY}
      type="submit"
      text={copy.login.label}
      disabled={isLoading}
      icon={
        isLoading ? (
          <Spinner intent={Intent.PRIMARY} size={SpinnerSize.SMALL} />
        ) : null
      }
    />
  ) : null;

  const authenticate = async (event: any) => {
    event?.preventDefault();
    const isUsingBasicAuth: boolean =
      Boolean(state.username) || Boolean(state.password);
    mutate(
      {
        username: state.username,
        password: state.password,
        browserAuth: !isUsingBasicAuth,
      },
      {
        onSuccess: (response) => {
          setIsDialogOpen(false);
          redirectToUrl(response.next);
        },
      }
    );
  };

  return isDialogOpen ? (
    <Dialog
      icon={IconNames.OFFLINE}
      title={copy.login.title(friendlyName)}
      className={classNames('sd-dialog-connection')}
      isCloseButtonShown={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      isOpen={isDialogOpen}
    >
      <form onSubmit={authenticate} method="POST">
        {passwordAuthForm}
        <div className={Classes.DIALOG_FOOTER}>
          <div className="controls">
            {passwordAuthConnectButton}
            {separator}
            {geoaxisButton}
            {googleButton}
            {keycloakButton}
            {azureButton}
            {openidButton}
          </div>
        </div>
      </form>
    </Dialog>
  ) : (
    <Loading />
  );
};
