import * as React from 'react';
import { useQueryClient } from 'react-query';

import { useAppDispatch } from 'src/ui/app/hooks';
import { FormAction } from 'src/ui/components/FormActions';
import { AnyFunc } from 'src/ui/constants/types';
import { ConnectionForm } from 'src/ui/containers/dialogs/ConnectionForm';
import { makeSubmitAction } from 'src/ui/containers/dialogs/makeSubmitAction';
import {
  Connection,
  ConnectionStatus,
  disconnected,
  setConnectionStatus,
} from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { AddConnectionMutation } from 'src/ui/graph/sdk';
import {
  useAddConnectionMutation,
  useSavedConnectionsQuery,
  useUserPartnerConnectionsQuery,
} from 'src/ui/graph/types';
import {
  useClearConnection,
  useSetConnectionIndex,
  useSetConnectionIndexForPartnerConnect,
  useVerifyConnection,
} from 'src/ui/hooks/connection';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';
import { doServiceRedirect } from 'src/ui/utils/window';

interface NewConnectionFormProps {
  connection?: Connection;
  redirectSubPath?: string;
}

export const NewConnectionForm: React.VFC<NewConnectionFormProps> = ({
  connection,
  redirectSubPath,
}) => {
  const { data: partnerInfo } = useUserPartnerConnectionsQuery(graphQLClient);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const clearConnection = useClearConnection();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const setConnectionIndex = useSetConnectionIndex();
  const setConnectionIndexForPartner = useSetConnectionIndexForPartnerConnect();
  const { data, isLoading } = useSavedConnectionsQuery(graphQLClient);
  const { isLoading: isValidating, mutate: validate } = useVerifyConnection();
  const newConnection = connection || disconnected;

  const {
    isLoading: isAddingConnection,
    mutate: addConnection,
  } = useAddConnectionMutation(graphQLClient, {
    onError,
    onMutate,
    onSuccess: (c: AddConnectionMutation) => {
      if (!c.addConnection) {
        clearConnection();
        return;
      }

      // Invalidate the query so that the new connection shows up in the list
      queryClient.invalidateQueries(useSavedConnectionsQuery.getKey());

      if (redirectSubPath) {
        doServiceRedirect(c.addConnection.index, redirectSubPath);
        return;
      }

      if (
        partnerInfo?.userPartnerConnections &&
        partnerInfo?.userPartnerConnections.length > 0
      ) {
        setConnectionIndexForPartner(
          c.addConnection.index,
          `/databricks-partner-home`
        );
        return;
      }

      setConnectionIndex(c.addConnection.index);
    },
  });

  const [savedNames, setSavedNames] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!data || !data.listConnections) {
      return;
    }
    setSavedNames(data.listConnections.map((conn) => conn?.name || 'unknown'));
  }, [data]);

  const handleConnect = (conn: Connection, reset: AnyFunc) => {
    validate(conn, {
      onError,
      onSuccess: (data) => {
        dispatch(setConnectionStatus(ConnectionStatus.PENDING));
        addConnection({ input: data }, { onSuccess: reset });
      },
    });
  };

  const isWorking = isLoading || isAddingConnection || isValidating;
  const submitDisabled = (conn: Connection) =>
    isWorking || submitDisabledText(conn) !== '';

  const submitDisabledText = (conn: Connection): string => {
    if (!conn.useSSO && !conn.useBrowserAuth && !conn.username) {
      return copy.components.connectionDialog.formValidation.usernameRequired;
    }

    if (
      !conn.useSSO &&
      !conn.useBrowserAuth &&
      conn.username &&
      !conn.password
    ) {
      return copy.components.connectionDialog.formValidation.passwordRequired;
    }

    if (!conn.endpoint) {
      return copy.components.connectionDialog.formValidation.endpointRequired;
    }

    if (conn.endpoint && !conn.endpoint.startsWith('http')) {
      return copy.components.connectionDialog.formValidation.invalidEndpoint;
    }

    if (savedNames.includes(conn.name)) {
      return copy.components.connectionDialog.formValidation.nameUniq;
    }

    if (!conn.name) {
      return copy.components.connectionDialog.formValidation.nameRequired;
    }

    return '';
  };

  const actions: FormAction[] = [
    makeSubmitAction(copy.components.connectionDialog.new.submit, isWorking),
  ];

  return (
    <ConnectionForm
      actions={actions}
      connection={newConnection}
      onSubmit={handleConnect}
      submitDisabled={submitDisabled}
      submitTooltip={submitDisabledText}
    />
  );
};
