import {
  Icon,
  IconSize,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Cookie } from 'vet-bones/components';
import { cookiebotAgent } from 'vet-bones/utils';

import { useAppDispatch } from 'src/ui/app/hooks';
import { PopoverMenu } from 'src/ui/components/PopoverMenu';
import { CONNECT_ICON_TEST_ID } from 'src/ui/constants/testIds';
import { DialogType, openedDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useGetStripeSessionUrlMutation } from 'src/ui/graph/types';
import { useProfile } from 'src/ui/hooks/connection';
import * as copy from 'src/ui/templates/copy';
import { colors } from 'src/ui/templates/styles/colors';
import { redirectToUrl } from 'src/ui/utils/window';

interface MenuPortalProps {
  customerId: string | undefined | null;
  isStaff: boolean | undefined;
  username: string | undefined;
}

export const MenuPortal: React.VFC<MenuPortalProps> = ({
  customerId,
  isStaff,
  username,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { mutate } = useGetStripeSessionUrlMutation(graphQLClient, {
    onSuccess: (data) => {
      redirectToUrl(data?.getStripeSessionUrl?.url || '/');
    },
  });

  const handleCustomerRedirect = () => {
    mutate({});
  };

  const handleLogout = () => {
    redirectToUrl('/auth/logout');
  };

  const handleManageApiKeys = () => {
    history.push('/keys');
  };

  const handleUpdateProfile = () => {
    dispatch(openedDialog({ type: DialogType.UPDATE_PROFILE }));
  };

  return (
    <Menu>
      <MenuDivider title={username} />
      <MenuItem
        icon={IconNames.PERSON}
        onClick={handleUpdateProfile}
        text={copy.components.connectionButton.updateProfile}
      />
      {customerId ? (
        <MenuItem
          icon={IconNames.CREDIT_CARD}
          onClick={handleCustomerRedirect}
          text={copy.components.connectionButton.manageBilling}
        />
      ) : null}
      {isStaff ? (
        <MenuItem
          icon={IconNames.KEY}
          onClick={handleManageApiKeys}
          text={copy.components.connectionButton.manageApiTokens}
        />
      ) : null}
      {cookiebotAgent.loaded ? (
        <MenuItem
          icon={<Cookie />}
          onClick={() => cookiebotAgent.openDialog()}
          text={copy.components.connectionButton.updateCookieConsent}
        />
      ) : null}
      <MenuItem
        icon={IconNames.LOG_OUT}
        onClick={handleLogout}
        text={copy.components.connectionButton.logout}
      />
      <MenuDivider />
      <MenuItem
        intent={Intent.DANGER}
        icon={IconNames.DELETE}
        onClick={() =>
          dispatch(openedDialog({ type: DialogType.DELETE_ACCOUNT }))
        }
        text={copy.components.connectionButton.deleteAccount}
      />
    </Menu>
  );
};

export const ConnectionButton: React.VFC = () => {
  const { data } = useProfile();
  const [isConnected, setIsConnected] = React.useState(false);

  React.useEffect(() => {
    if (data?.profile?.is_authenticated) {
      setIsConnected(true);
    }
  }, [data]);

  /* istanbul ignore next: pretty simple redirect */
  const handleLogin = () => redirectToUrl('/login');

  const menu = isConnected ? (
    <MenuPortal
      customerId={data?.profile?.stripe_customer?.customer_id}
      isStaff={data?.profile?.is_staff || false}
      username={data?.profile?.username || 'unknown'}
    />
  ) : (
    <Menu>
      <MenuItem
        icon={IconNames.LOG_IN}
        onClick={handleLogin}
        text={copy.components.connectionButton.login}
      />
    </Menu>
  );

  return (
    <PopoverMenu
      content={menu}
      tooltip="Stardog Account"
      testId={CONNECT_ICON_TEST_ID}
      icon={
        <Icon
          color={isConnected ? colors.connected : undefined}
          icon={IconNames.USER}
          iconSize={IconSize.LARGE}
        />
      }
    />
  );
};
