import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Messages } from 'src/ui/components/Messages';
import { NotFound } from 'src/ui/components/NotFound';
import { Notifications } from 'src/ui/components/Notifications';
import { PrivateRoute } from 'src/ui/components/PrivateRoute';
import { Diagnostic } from 'src/ui/containers/diagnostic/Diagnostic';
import { AppHooks } from 'src/ui/containers/on-prem/AppHooks';
import { AppNavBar } from 'src/ui/containers/on-prem/AppNavBar';
import { Dashboard } from 'src/ui/containers/on-prem/Dashboard';
import { Login } from 'src/ui/containers/on-prem/Login';
import { PortalRedirect } from 'src/ui/containers/on-prem/PortalRedirect';
import { getFriendlyName } from 'src/ui/utils/window';

export const OnPremApp: React.VFC = () => {
  React.useEffect(() => {
    document.title = getFriendlyName();
  }, []);

  return (
    <>
      <main>
        <AppNavBar />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/diagnostic">
            <Diagnostic />
          </Route>
          <PrivateRoute exact path="/">
            <Dashboard />
          </PrivateRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </main>
      <AppHooks />
      <Notifications />
      <Messages />
      <PortalRedirect />
    </>
  );
};
