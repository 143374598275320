import { Button, Classes, InputGroup, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  ItemRenderer,
  ItemRendererProps,
  QueryList,
} from '@blueprintjs/select';
import classNames from 'classnames';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VoiceboxLogo } from 'vet-bones/components';
import { useDidValueChange } from 'vet-bones/utils/hooks';
import { selectItemPredicate } from 'vet-bones/utils/select';

import { useAppSelector } from 'src/ui/app/hooks';
import {
  VOICEBOX_PAGE_SIDEBAR_NEW,
  VOICEBOX_PAGE_SIDEBAR_SEARCH_BUTTON,
  VOICEBOX_PAGE_SIDEBAR_SEARCH_CLOSE_BUTTON,
  VOICEBOX_PAGE_SIDEBAR_SEARCH_INPUT,
} from 'src/ui/constants/testIds';
import { EditVoiceboxPageSidebarItem } from 'src/ui/containers/voicebox/EditVoiceboxPageSidebarItem';
import {
  ConversationSelectItem,
  TParams,
  useVoiceboxConversations,
} from 'src/ui/containers/voicebox/useVoiceboxConversations';
import { isVoiceboxSidebarExpandedSelector } from 'src/ui/selectors/isVoiceboxSidebarExpanded';
import * as copy from 'src/ui/templates/copy';

interface VoiceboxPageSidebarProps {
  connectionId: string;
  onNewConversation: () => void;
}

export const VoiceboxPageSidebar: React.VFC<VoiceboxPageSidebarProps> = ({
  connectionId,
  onNewConversation,
}) => {
  const params = useParams<TParams>();
  const history = useHistory();

  const isExpanded = useAppSelector(isVoiceboxSidebarExpandedSelector());

  const conversations = useVoiceboxConversations(connectionId);
  const conversationItems = conversations.items;

  const selected =
    conversationItems.find(
      (conversation) => conversation.value === params.conversation
    ) || null;

  const didFetchingConversationChange = useDidValueChange(
    conversations.isFetching
  );
  const didNotFindConversation =
    didFetchingConversationChange &&
    !conversations.isFetching &&
    !selected &&
    params.conversation;
  React.useEffect(() => {
    if (didNotFindConversation) {
      onNewConversation();
      history.push(`/u/${params.id}/voicebox/`);
    }
  }, [didNotFindConversation, history, onNewConversation, params]);

  const [isInputCollapsed, setIsInputCollapsed] = React.useState(true);

  const listRef = React.createRef<QueryList<ConversationSelectItem>>();
  const inputRef = React.createRef<HTMLInputElement>();

  const resetQuery = () => {
    setIsInputCollapsed(true);
    listRef.current?.setQuery('', true);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const onSelect = (item: ConversationSelectItem) => {
    if (item.value !== params.conversation) {
      history.push(`/u/${params.id}/voicebox/${item.value}`);
    }
  };

  const itemPredicate = selectItemPredicate()(conversationItems);

  const itemRenderer = React.useCallback<ItemRenderer<ConversationSelectItem>>(
    (item: ConversationSelectItem, options: ItemRendererProps) => {
      return options.modifiers.matchesPredicate ? (
        <React.Fragment key={item.value}>
          <EditVoiceboxPageSidebarItem
            item={item}
            options={options}
            connectionId={connectionId}
          />
        </React.Fragment>
      ) : null;
    },
    [connectionId]
  );
  return (
    <div
      className={classNames('sd-voicebox-page-sidebar', {
        'sd-voicebox-page-sidebar--collapsed': !isExpanded,
      })}
    >
      {isExpanded ? (
        <QueryList
          ref={listRef}
          itemPredicate={itemPredicate}
          itemRenderer={itemRenderer}
          items={conversationItems}
          noResults={
            conversationItems.length
              ? copy.components.voicebox.sidebar.noResults
              : ''
          }
          onItemSelect={onSelect}
          renderer={(listProps) => {
            const queryListInput = isInputCollapsed ? (
              <Button
                icon={IconNames.SEARCH}
                data-testid={VOICEBOX_PAGE_SIDEBAR_SEARCH_BUTTON}
                minimal
                onClick={() => {
                  setIsInputCollapsed(false);
                }}
              />
            ) : (
              <InputGroup
                autoFocus
                data-testid={VOICEBOX_PAGE_SIDEBAR_SEARCH_INPUT}
                inputRef={inputRef}
                leftIcon={IconNames.SEARCH}
                onBlur={
                  /* istanbul ignore next */
                  (evt) => {
                    const { value } = evt.currentTarget;
                    if (!value) {
                      resetQuery();
                    }
                  }
                }
                onChange={listProps.handleQueryChange}
                placeholder={
                  copy.components.voicebox.sidebar.filter.placeholder
                }
                rightElement={
                  <Button
                    icon={IconNames.CROSS}
                    minimal
                    onClick={resetQuery}
                    // Prevent clicks from stealing focus
                    onMouseDown={
                      /* istanbul ignore next */
                      (evt) => evt.preventDefault()
                    }
                    data-testid={VOICEBOX_PAGE_SIDEBAR_SEARCH_CLOSE_BUTTON}
                  />
                }
              />
            );
            return (
              <>
                <div
                  className={classNames(
                    'sd-voicebox-page-sidebar-header',
                    Classes.ELEVATION_1
                  )}
                >
                  <VoiceboxLogo colorful size={30} />
                  <div className="sd-voicebox-page-sidebar-actions">
                    {queryListInput}
                    <Button
                      className="sd-voicebox-page-sidebar-actions-new"
                      data-testid={VOICEBOX_PAGE_SIDEBAR_NEW}
                      icon={IconNames.Plus}
                      intent={Intent.PRIMARY}
                      outlined
                      onClick={(evt) => {
                        evt.preventDefault();
                        onNewConversation();
                        history.push(`/u/${params.id}/voicebox/`);
                      }}
                      text={
                        isInputCollapsed
                          ? copy.components.voicebox.sidebar.new
                          : ''
                      }
                    />
                  </div>
                </div>
                <div className="sd-voicebox-page-sidebar-body">
                  {listProps.itemList}
                </div>
              </>
            );
          }}
        />
      ) : null}
    </div>
  );
};
