import * as React from 'react';

import { AppNavBar } from 'src/ui/components/AppNavBar';
import { PortalNavbarWrapper } from 'src/ui/components/PortalNavbarWrapper';
import { UnverifiedEmailWarning } from 'src/ui/components/UnverifiedEmailWarning';
import { AppDialogs } from 'src/ui/containers/AppDialogs';
import { AppHooks } from 'src/ui/containers/AppHooks';

export const App: React.VFC = () => {
  return (
    <>
      <main>
        <AppHooks />
        <AppNavBar />
        <UnverifiedEmailWarning />
        <PortalNavbarWrapper />
      </main>
      <AppDialogs />
    </>
  );
};
