import { Classes } from '@blueprintjs/core';
import * as React from 'react';

import { copy } from 'src/ui/templates/copy';
import keycloakButton from 'src/ui/templates/images/keycloak.svg';
import { doKeycloakRedirect } from 'src/ui/utils/window';

/**
 * This uses just a plain button as we use an image to display the actual
 * button. We don't want this to be styled by blueprintjs.
 */
export const KeycloakLink: React.VFC = () => {
  return (
    <button
      type="button"
      onClick={doKeycloakRedirect}
      className={`${Classes.BUTTON} oauth`}
    >
      <img src={keycloakButton} alt={copy.keycloak.label} />
      {copy.keycloak.label}
    </button>
  );
};
