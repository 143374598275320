import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Loading } from 'src/ui/components/Loading';
import { ProductCard } from 'src/ui/components/ProductCard';
import {
  useCurrentConnection,
  useVerifyConnection,
} from 'src/ui/hooks/connection';
import { copy } from 'src/ui/templates/copy';
import stardogDesigner from 'src/ui/templates/images/stardog-designer.svg';
import stardogExplorer from 'src/ui/templates/images/stardog-explorer.svg';
import stardogStudio from 'src/ui/templates/images/stardog-studio.svg';
import {
  doDesignerRedirect,
  doExplorerRedirect,
  doStudioRedirect,
} from 'src/ui/utils/window';

export const Dashboard: React.VFC = () => {
  const { connection } = useCurrentConnection('0');
  const { isLoading: isValidating, mutate: validate } = useVerifyConnection();
  const username = connection?.username;
  const connectionString = `${username} - ${connection?.endpoint}`;
  const history = useHistory();

  // This function is run every time the connection changes, for example when the
  // url is changed or when the initial connection information is resolved.
  React.useEffect(() => {
    if (!connection) {
      return;
    }

    validate(connection, {
      onError: (_error) => {
        history.push('/diagnostic');
      },
    });
  }, [connection]);

  const goToStudio = () => {
    doStudioRedirect();
  };

  const goToExplorer = () => {
    doExplorerRedirect();
  };

  const goToDesigner = () => {
    doDesignerRedirect();
  };

  return isValidating ? (
    <Loading />
  ) : (
    <div className="sd-list">
      <div className="sd-list__collection dashboard-cards">
        <ProductCard
          onClick={goToExplorer}
          image={stardogExplorer}
          altText={copy.dashboard.explorer.action}
          label={copy.dashboard.explorer.label}
          description={copy.dashboard.explorer.description}
        />
        <ProductCard
          onClick={goToDesigner}
          image={stardogDesigner}
          altText={copy.dashboard.designer.action}
          label={copy.dashboard.designer.label}
          description={copy.dashboard.designer.description}
        />
        <ProductCard
          onClick={goToStudio}
          image={stardogStudio}
          altText={copy.dashboard.studio.action}
          label={copy.dashboard.studio.label}
          description={copy.dashboard.studio.description}
        />
      </div>
      <footer>
        <p>{connectionString}</p>
      </footer>
    </div>
  );
};
