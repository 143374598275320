import { Alignment, Navbar } from '@blueprintjs/core';
import * as React from 'react';

import { NAV_CONTAINER } from 'src/ui/constants/testIds';
import { ConnectionButton } from 'src/ui/containers/on-prem/ConnectionButton';
import { copy } from 'src/ui/templates/copy';
import logo from 'src/ui/templates/images/Stardog_Cloud_Logo_Light.svg';
import { getFriendlyName } from 'src/ui/utils/window';

const title = getFriendlyName();

export const AppNavBar: React.VFC = (): React.ReactElement => (
  <Navbar className="sd-app-navbar" data-testid={NAV_CONTAINER}>
    <Navbar.Group align={Alignment.LEFT}>
      <a className="sd-app-navbar__logo" href="/">
        <img src={logo} alt={copy.appNavBar.altLogo} title={title} />
      </a>
    </Navbar.Group>
    <Navbar.Group align={Alignment.RIGHT}>
      <ConnectionButton />
    </Navbar.Group>
  </Navbar>
);
