import { Menu, MenuItem } from '@blueprintjs/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

export const LearnMenu: React.VFC = () => {
  const history = useHistory();

  return (
    <Menu>
      <MenuItem onClick={() => history.push('/kits')} text="Knowledge Kits" />
      <MenuItem
        text="Learning Portal"
        target="_blank"
        href="https://www.stardog.com/learn-stardog/"
      />
      <MenuItem
        text="Documentation"
        target="_blank"
        href="https://docs.stardog.com"
      />
      <MenuItem
        text="Stardog Labs"
        target="_blank"
        href="https://www.stardog.com/labs/"
      />
      <MenuItem
        text="Community Support"
        target="_blank"
        href="https://community.stardog.com/c/stardog-cloud/"
      />
      <MenuItem
        text="Trainings"
        target="_blank"
        href="https://academy.stardog.com/learn"
      />
    </Menu>
  );
};
