import { Classes, H4, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Dialog } from 'src/ui/components/Dialog';
import { FormAction, FormActions } from 'src/ui/components/FormActions';
import { Loading } from 'src/ui/components/Loading';
import { Connection } from 'src/ui/features/connection';
import { DialogType, closedDialog } from 'src/ui/features/ui';
import { useCurrentConnection } from 'src/ui/hooks/connection';
import { isDialogOpenSelector } from 'src/ui/selectors/isDialogOpen';
import * as copy from 'src/ui/templates/copy';
import { doPortalLink } from 'src/ui/utils/window';

const isOpenSelector = isDialogOpenSelector(DialogType.PORTAL_REDIRECT);

export const PortalRedirect: React.VFC = () => {
  const dispatch = useAppDispatch();
  const { connection, isLoading } = useCurrentConnection('0');
  const isOpen = useAppSelector(isOpenSelector);

  const handleCloseDialog = () => {
    dispatch(closedDialog());
  };

  const handleSubmit = () => {
    doPortalLink(connection as Connection);
  };

  const actions: FormAction[] = [
    {
      text: copy.components.portalRedirect.submit,
      isSubmit: true,
      intent: Intent.PRIMARY,
    },
    { text: copy.components.portalRedirect.cancel, onClick: handleCloseDialog },
  ];

  const dialogBody = isLoading ? (
    <Loading />
  ) : (
    <form className="sd-connection-form" onSubmit={handleSubmit}>
      <div className={Classes.DIALOG_BODY}>
        <H4>{copy.components.portalRedirect.submit}</H4>
        <ul>
          {copy.components.portalRedirect.reasons.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <FormActions
        actions={actions}
        handleSubmit={handleSubmit}
        isSubmitDisabled={false}
      />
    </form>
  );

  return (
    <Dialog
      className="sd-dialog-connection"
      icon={IconNames.OFFLINE}
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title={copy.components.portalRedirect.title}
    >
      {dialogBody}
    </Dialog>
  );
};
