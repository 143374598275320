import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFound } from 'src/ui/components/NotFound';
import { PrivateRoute } from 'src/ui/components/PrivateRoute';
import { ManageApiTokens } from 'src/ui/containers/api-tokens/ManageApiTokens';
import { ManageConnections } from 'src/ui/containers/connection-list/ManageConnections';
import { Configuration } from 'src/ui/containers/dashboard/Configuration';
import { Diagnostic } from 'src/ui/containers/diagnostic/Diagnostic';
import { Connect } from 'src/ui/containers/dialogs/Connect';
import { GetStarted } from 'src/ui/containers/get-started/GetStarted';
import { CloudAdmin } from 'src/ui/containers/internal/CloudAdmin';
import { CloudReport } from 'src/ui/containers/internal/CloudReport';
import { Home as EmployeeHome } from 'src/ui/containers/internal/Home';
import { UserDashboard } from 'src/ui/containers/internal/UserDashboard';
import { UserSearch } from 'src/ui/containers/internal/UserSearch';
import { Invitation } from 'src/ui/containers/invitation/Invitation';
import { LandingPage } from 'src/ui/containers/landing/LandingPage';
import { Login } from 'src/ui/containers/Login';
import { KitOverview } from 'src/ui/containers/marketplace/KitOverview';
import { MPHome } from 'src/ui/containers/marketplace/MPHome';
import { Databricks } from 'src/ui/containers/partner/databricks/Databricks';
import { Share } from 'src/ui/containers/share/Share';
import { VoiceboxPage } from 'src/ui/containers/voicebox/VoiceboxPage';

export const AppBody: React.VFC = () => {
  const uuidRegex =
    '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
  return (
    <div className="sd-app-body">
      <div className="sd-vertical">
        <Switch>
          <Route path="/login/:customer?">
            <Login />
          </Route>
          <Route path="/diagnostic">
            <Diagnostic />
          </Route>
          <PrivateRoute path="/internal/cloud/admin" adminOnly>
            <CloudAdmin />
          </PrivateRoute>
          <PrivateRoute path="/internal/cloud/report/:days([\d]+)" staffOnly>
            <CloudReport />
          </PrivateRoute>
          <PrivateRoute path="/internal/cloud/report" staffOnly>
            <CloudReport />
          </PrivateRoute>
          <PrivateRoute path={`/internal/user/:id(${uuidRegex})`} staffOnly>
            <UserDashboard />
          </PrivateRoute>
          <PrivateRoute path="/internal/user/search" staffOnly>
            <UserSearch />
          </PrivateRoute>
          <PrivateRoute path="/internal" staffOnly>
            <EmployeeHome />
          </PrivateRoute>
          <PrivateRoute path="/get-started/:flavor([\w]+)">
            <GetStarted />
          </PrivateRoute>
          <PrivateRoute path="/get-started">
            <GetStarted />
          </PrivateRoute>
          <PrivateRoute path="/connect">
            <Connect />
          </PrivateRoute>
          <PrivateRoute path="/u/:id(\d+)/configuration">
            <Configuration />
          </PrivateRoute>
          <PrivateRoute path="/u/:id(\d+)/diagnostic">
            <Diagnostic />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/u/:id(\d+)/voicebox/:conversation?"
            voiceboxOnly
          >
            <VoiceboxPage />
          </PrivateRoute>
          <PrivateRoute exact path="/u/:id(\d+|demo)/">
            <LandingPage />
          </PrivateRoute>
          <PrivateRoute exact path="/kits">
            <MPHome />
          </PrivateRoute>
          <PrivateRoute exact path="/kits/:id([\w:\d-\.%]+)">
            <KitOverview />
          </PrivateRoute>
          <PrivateRoute exact path="/keys" staffOnly>
            <ManageApiTokens />
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <LandingPage />
          </PrivateRoute>
          <PrivateRoute exact path="/connections">
            <ManageConnections />
          </PrivateRoute>
          <PrivateRoute path="/databricks-partner-home">
            <Databricks />
          </PrivateRoute>
          <PrivateRoute path="/share/:hash([\w\d]+)">
            <Share />
          </PrivateRoute>
          <Route path="/invitation/:id([\w\d-]+)">
            <Invitation />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
