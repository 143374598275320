import * as React from 'react';

import { ConnectionList } from 'src/ui/containers/connection-list/ConnectionList';
import { components } from 'src/ui/templates/copy';

export const ManageConnections: React.VFC = () => {
  return (
    <div className="manage-connections-container">
      <ConnectionList
        header={components.manageConnections.header}
        redirectWhenNoConnections
      />
    </div>
  );
};
