import { Code, H2 } from '@blueprintjs/core';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
// These imports are from `dist/cjs` rather than `dist/esm` as described in the
// documentation because of an error with jest test framework.
// See: https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/221
import properties from 'react-syntax-highlighter/dist/cjs/languages/prism/properties';
import yaml from 'react-syntax-highlighter/dist/cjs/languages/prism/yaml';
import prism from 'react-syntax-highlighter/dist/cjs/styles/prism/prism';

import { useAppDispatch } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { setConnectionIndex } from 'src/ui/features/connection';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useGenerateConfigurationMutation } from 'src/ui/graph/types';
import { useCurrentConnection } from 'src/ui/hooks/connection';

type TParams = { id: string };

// We are using the 'Light' SyntaxHighlighter to reduce the bundle size by about 500k.
// This requires us to explicitly import and register the languages we want to highlight.
// See: https://www.npmjs.com/package/react-syntax-highlighter#light-build
SyntaxHighlighter.registerLanguage('yaml', yaml);
SyntaxHighlighter.registerLanguage('properties', properties);

/* istanbul ignore next: FIXME add tests */
export const Configuration: React.VFC = () => {
  const dispatch = useAppDispatch();
  const params = useParams<TParams>();
  const { connection } = useCurrentConnection(params.id);
  const {
    mutate: generateConfiguration,
    isLoading,
  } = useGenerateConfigurationMutation(graphQLClient);
  const [jwtConfig, setJwtConfig] = React.useState<string>('');
  const [properties, setProperties] = React.useState<string>('');

  /* istanbul ignore next: this just keeps state in sync */
  React.useEffect(() => {
    dispatch(setConnectionIndex(Number(params.id)));
  }, [params]);

  // This function is run every time the connection changes, for example when the
  // url is changed or when the initial connection information is resolved.
  React.useEffect(() => {
    if (!connection) {
      return;
    }

    const { endpoint } = connection;

    generateConfiguration(
      { endpoint },
      {
        onSuccess: (data) => {
          setJwtConfig(data.generateConfiguration?.jwt_config || '');
          setProperties(data.generateConfiguration?.properties || '');
        },
      }
    );
  }, [connection]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="sd-diagnostic">
      <H2>Example Configuration</H2>
      <p>
        Please ask your admin to add the following configuration file on your
        server in a secure location like:&nbsp;
        <Code>/etc/stardog/jwt.yaml</Code>
      </p>
      <SyntaxHighlighter language="yaml" style={prism}>
        {jwtConfig}
      </SyntaxHighlighter>
      <p>
        To enable this configuration they will also need to edit the&nbsp;
        <Code>stardog.properties</Code>
        &nbsp;file and restart the server
      </p>
      <SyntaxHighlighter language="properties" style={prism}>
        {properties}
      </SyntaxHighlighter>
    </div>
  );
};
