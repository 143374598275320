import axios from 'axios';
import { useMutation } from 'react-query';

import { useAppDispatch } from 'src/ui/app/hooks';
import {
  clearNotifications,
  queueNotification,
} from 'src/ui/features/notifications';
import { copy } from 'src/ui/templates/copy';
import { getNext } from 'src/ui/utils/window';

export type ValidateArgs = {
  username: string;
  password: string;
  browserAuth: boolean;
};

export type ValidateResponse = {
  next: string;
};

export const validateCredentials = ({
  username,
  password,
  browserAuth,
}: ValidateArgs) => {
  const hash = btoa(`${username}:${password}`);
  const authHeader = `Basic ${hash}`;
  const requestConfig = browserAuth
    ? { withCredentials: true }
    : {
        headers: {
          Accept: 'application/json',
          Authorization: authHeader,
        },
      };
  const url = browserAuth ? '/auth/browserauth' : '/auth/userpass';

  return axios
    .post(url, null, requestConfig)
    .then(() => {
      const nextEncoded = getNext();
      const next = decodeURIComponent(nextEncoded);

      return { next };
    })
    .catch((error) => {
      const response = error.response || { status: 999 };
      switch (response.status) {
        case 401: {
          throw new Error(copy.errors.invalidUserPassword);
        }
        case 404: {
          throw new Error(copy.errors.notFound);
        }
        default: {
          throw new Error(copy.errors.unknown);
        }
      }
    });
};

export const useAuthenticateMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation<ValidateResponse, Error, ValidateArgs>(
    validateCredentials,
    {
      onMutate: () => {
        dispatch(clearNotifications());
      },
      onError: (error) => {
        dispatch(queueNotification(error.message));
      },
    }
  );
};
