import {
  AnchorButton,
  EditableText,
  Menu,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import { ItemRendererProps } from '@blueprintjs/select';
import classNames from 'classnames';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useIsMounted } from 'vet-bones/utils/hooks';

import { withConfirmation } from 'src/ui/components/withConfirmation';
import {
  VOICEBOX_PAGE_SIDEBAR_ITEM,
  VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_CANCEL,
  VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_CONFIRMATION,
  VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_MENU_ITEM,
  VOICEBOX_PAGE_SIDEBAR_ITEM_MENU,
  VOICEBOX_PAGE_SIDEBAR_ITEM_RENAME_MENU_ITEM,
} from 'src/ui/constants/testIds';
import {
  ConversationSelectItem,
  TParams,
  useVoiceboxConversations,
} from 'src/ui/containers/voicebox/useVoiceboxConversations';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  useDeleteVoiceboxConversationMutation,
  useEditVoiceboxConversationMutation,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';

interface EditVoiceboxPageSidebarItemProps {
  item: ConversationSelectItem;
  options: ItemRendererProps;
  connectionId: string;
}
export const EditVoiceboxPageSidebarItem: React.VFC<EditVoiceboxPageSidebarItemProps> = ({
  item,
  options,
  connectionId,
}) => {
  const queryClient = useQueryClient();
  const params = useParams<TParams>();
  const conversations = useVoiceboxConversations(connectionId);
  const conversationItems = conversations.items;
  const [isHovered, setIsHovered] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [conversationName, setConversationName] = React.useState(item.text);
  const isMounted = useIsMounted();
  const selected =
    conversationItems.find(
      (conversation) => conversation.value === params.conversation
    ) || null;
  const onError = useOnError();
  const onMutate = useOnMutate();
  const { mutate: handleVoiceboxRename } = useEditVoiceboxConversationMutation(
    graphQLClient,
    {
      onSuccess: async (data) => {
        if (!data.editVoiceboxConversation?.success) {
          return;
        }
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        await queryClient.refetchQueries(['listVoiceboxConversations']);
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }

        setIsEditing(false);
        setIsMenuOpen(false);
      },
      onError,
      onMutate,
    }
  );

  const {
    mutate: handleVoiceboxDelete,
  } = useDeleteVoiceboxConversationMutation(graphQLClient, {
    onSuccess: async (data) => {
      if (!data.deleteVoiceboxConversation?.success) {
        return;
      }
      /* istanbul ignore if */
      if (!isMounted.current) {
        return;
      }
      await queryClient.refetchQueries(['listVoiceboxConversations']);
      /* istanbul ignore if */
      if (!isMounted.current) {
        return;
      }
      setIsMenuOpen(false);
    },
    onError,
    onMutate,
  });

  const deleteConversationConfirmation = () =>
    withConfirmation({
      message: copy.components.voicebox.sidebar.delete.message,
      noPrompt: copy.components.voicebox.sidebar.delete.cancel,
      noPromptTestId: VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_CANCEL,
      onConfirmationSuccess: () => {
        handleVoiceboxDelete({
          conversation_id: item.value,
        });
      },
      title: copy.components.voicebox.sidebar.delete.title,
      yesPrompt: copy.components.voicebox.sidebar.delete.delete,
      yesPromptTestId: VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_CONFIRMATION,
    });
  const inputRef = React.useRef<EditableText>(null);
  const [isEditingFocused, setIsEditingFocused] = React.useState(false);
  React.useEffect(() => {
    if (isEditing && inputRef.current && !isEditingFocused) {
      inputRef.current.toggleEditing();
      // in Blueprint the input element is Private and cannot be focused
      // @ts-ignore
      inputRef.current?.inputElement?.focus();
      setIsEditingFocused(true);
    }
  }, [isEditing, inputRef, isEditingFocused]);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setIsMenuOpen(false);
      }}
      className={classNames(
        'sd-voicebox-page-sidebar-body__row',
        isHovered && 'sd-voicebox-page-sidebar-body__row--hover'
      )}
    >
      {isEditing ? (
        <EditableText
          alwaysRenderInput
          confirmOnEnterKey
          onCancel={() => {
            setConversationName(item.text);
            setIsEditing(false);
          }}
          onChange={(name) => {
            setConversationName(name);
          }}
          onConfirm={() => {
            handleVoiceboxRename({
              input: {
                id: item.value,
                name: conversationName,
              },
            });
          }}
          placeholder={item.text}
          ref={inputRef}
          value={conversationName}
        />
      ) : (
        <>
          <div
            className={classNames(
              'sd-voicebox-page-sidebar-body__row__item',
              isHovered && 'sd-voicebox-page-sidebar-body__row__item--hover'
            )}
          >
            <MenuItem
              data-testid={VOICEBOX_PAGE_SIDEBAR_ITEM(
                item.value,
                options.index || 0
              )}
              onClick={async (evt) => {
                evt.preventDefault();
                options.handleClick(evt);
              }}
              selected={selected?.value === item.value}
              text={item.text}
            />
          </div>
          {isHovered ? (
            <Popover2
              content={
                <Menu>
                  <MenuItem
                    data-testid={VOICEBOX_PAGE_SIDEBAR_ITEM_RENAME_MENU_ITEM}
                    icon={IconNames.EDIT}
                    onClick={() => {
                      setConversationName(item.text);
                      setIsEditingFocused(false);
                      setIsEditing(true);
                      setIsHovered(false);
                    }}
                    text={copy.components.voicebox.sidebar.rename}
                  />
                  <MenuItem
                    data-testid={VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_MENU_ITEM}
                    icon={IconNames.TRASH}
                    onClick={deleteConversationConfirmation}
                    text={copy.components.voicebox.sidebar.delete.delete}
                  />
                </Menu>
              }
              isOpen={isMenuOpen}
              minimal
              onInteraction={(isOpen) => setIsMenuOpen(isOpen)}
              position={Position.BOTTOM_RIGHT}
            >
              <AnchorButton
                active={isMenuOpen}
                data-testid={VOICEBOX_PAGE_SIDEBAR_ITEM_MENU}
                style={{
                  visibility: isHovered || isMenuOpen ? 'visible' : 'hidden',
                }}
                icon={IconNames.MORE}
                minimal
              />
            </Popover2>
          ) : null}
        </>
      )}
    </div>
  );
};
