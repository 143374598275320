export const getFeatureFlag = (
  feature: string,
  optDefault = 'false'
): string => {
  const globalFeatures = window.FEATURES || {};
  const option = globalFeatures[feature];
  return typeof option === 'undefined' ? optDefault : option;
};

export const isEnabled = (feature: string, optDefault = 'false'): boolean => {
  return getFeatureFlag(feature, optDefault) === 'true';
};

// Our features
export const isAzureAuthEnabled = () => isEnabled('azureAuth');
export const isGeoaxisAuthEnabled = () => isEnabled('geoaxisAuth');
export const isGoogleAuthEnabled = () => isEnabled('googleAuth');
export const isKeycloakAuthEnabled = () => isEnabled('keycloakAuth');
export const isOpenIDAuthEnabled = () => isEnabled('openidAuth');
export const isPasswordAuthEnabled = () => isEnabled('passwordAuth');
export const isPortalEnabled = () => isEnabled('portal');
export const isDataMarketplaceEnabled = () => isEnabled('dataMarketplace');
export const isHomeFooterLinksEnabled = () => isEnabled('homeFooterLinks');
