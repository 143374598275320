import {
  Button,
  Card,
  H3,
  H6,
  Intent,
  Menu,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useIsMounted } from 'vet-bones/utils/hooks';

import { useAppDispatch } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { withConfirmation } from 'src/ui/components/withConfirmation';
import {
  MANAGE_API_TOKENS_APP_CREATE,
  MANAGE_API_TOKENS_APP_TABLE,
  MANAGE_VOICEBOX_APP_DELETE_CANCEL,
  MANAGE_VOICEBOX_APP_DELETE_SUBMIT,
  MANAGE_VOICEBOX_APP_MENU,
  MANAGE_VOICEBOX_APP_MENU_DELETE,
  MANAGE_VOICEBOX_APP_MENU_UPDATE,
} from 'src/ui/constants/testIds';
import { ApiTokensTable } from 'src/ui/containers/api-tokens/ApiTokenTable';
import {
  showCreateApiTokenDialog,
  showUpdateVoiceboxAppDialog,
} from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  ApiToken,
  VoiceboxApp,
  useDeleteVoiceboxAppMutation,
  useListApiTokensQuery,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { useOnError, useOnMutate } from 'src/ui/hooks/graph';
import * as copy from 'src/ui/templates/copy';

interface VoiceboxAppCardProps {
  app: VoiceboxApp;
  idx?: number;
}

export const VoiceboxAppCard: React.VFC<VoiceboxAppCardProps> = ({ app }) => {
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const onError = useOnError();
  const onMutate = useOnMutate();
  const queryClient = useQueryClient();

  const connectionsQuery = useSavedConnectionsQuery(graphQLClient);

  const selectedConnection = connectionsQuery.data?.listConnections?.find(
    (connection) => connection?.id === app.connection_id
  );

  const { data, isLoading } = useListApiTokensQuery(graphQLClient, {
    app_id: app.id,
  });

  const { mutate: deleteVoiceboxApp } = useDeleteVoiceboxAppMutation(
    graphQLClient,
    {
      onError,
      onMutate,
      onSuccess: async (data) => {
        if (!data.deleteVoiceboxApp?.success) {
          return;
        }
        /* istanbul ignore if */
        if (!isMounted.current) {
          return;
        }
        await queryClient.refetchQueries(['listVoiceboxApps']);
      },
    }
  );

  const apiTokens =
    data?.listApiTokens?.filter((x): x is ApiToken => Boolean(x)) || [];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Card key={app.id} className="sd-voicebox-app-card">
      <div
        className="sd-table"
        data-testid={MANAGE_API_TOKENS_APP_TABLE(app.id)}
      >
        <div className="sd-table__header">
          <H3>{app.name}</H3>
          <div className="sd-table__actions">
            <div className="sd-table__actions__button-container">
              <Popover2
                minimal
                content={
                  <Menu>
                    <MenuItem
                      data-testid={MANAGE_VOICEBOX_APP_MENU_UPDATE}
                      icon={IconNames.EDIT}
                      text={
                        copy.components.manageApiTokens.voiceboxAppCard.update
                          .menu
                      }
                      onClick={() =>
                        dispatch(showUpdateVoiceboxAppDialog({ app }))
                      }
                    />
                    <MenuItem
                      data-testid={MANAGE_VOICEBOX_APP_MENU_DELETE}
                      icon={IconNames.TRASH}
                      text={
                        copy.components.manageApiTokens.voiceboxAppCard.delete
                          .menu
                      }
                      intent={Intent.DANGER}
                      onClick={() =>
                        withConfirmation({
                          message:
                            copy.components.manageApiTokens.voiceboxAppCard
                              .delete.confirmationMessage,
                          noPrompt:
                            copy.components.manageApiTokens.voiceboxAppCard
                              .delete.cancel,
                          noPromptTestId: MANAGE_VOICEBOX_APP_DELETE_CANCEL,
                          onConfirmationSuccess: () => {
                            deleteVoiceboxApp({ id: app.id });
                          },
                          title:
                            copy.components.manageApiTokens.voiceboxAppCard
                              .delete.title,
                          yesPrompt:
                            copy.components.manageApiTokens.voiceboxAppCard
                              .delete.submit,
                          yesPromptTestId: MANAGE_VOICEBOX_APP_DELETE_SUBMIT,
                        })
                      }
                    />
                  </Menu>
                }
                position={Position.BOTTOM_LEFT}
              >
                <Button
                  data-testid={MANAGE_VOICEBOX_APP_MENU}
                  icon={IconNames.MORE}
                  minimal
                />
              </Popover2>
            </div>
          </div>
        </div>
        <div className="sd-voicebox-app-card-config">
          <p className="sd-voicebox-app-card-config--connection">
            <strong>
              {copy.components.manageApiTokens.voiceboxConfig.connection.label}
            </strong>
            {selectedConnection ? (
              <Link to={`/u/${selectedConnection.index}`}>
                {selectedConnection.name} ({selectedConnection.username}@
                {selectedConnection.endpoint})
              </Link>
            ) : null}
          </p>
          <p>
            <strong>
              {copy.components.manageApiTokens.voiceboxConfig.database.label}
            </strong>
            {app.database}
          </p>
          <p>
            <strong>
              {copy.components.manageApiTokens.voiceboxConfig.model.label}
            </strong>
            {app.model ||
              copy.components.manageApiTokens.voiceboxConfig.model.default}
          </p>
          <p>
            <strong>
              {copy.components.manageApiTokens.voiceboxConfig.namedGraphs.label}
            </strong>
            {app.named_graphs?.join(', ')}
          </p>
          <p>
            <strong>
              {copy.components.manageApiTokens.voiceboxConfig.reasoning.label}
            </strong>
            {app.reasoning
              ? copy.components.manageApiTokens.voiceboxConfig.reasoning.enabled
              : copy.components.manageApiTokens.voiceboxConfig.reasoning
                  .disabled}
          </p>
        </div>

        <div className="sd-table__header">
          <H6>{copy.components.manageApiTokens.voiceboxAppCard.tokens}</H6>
          <div className="sd-table__actions">
            <div className="sd-table__actions__button-container">
              <Button
                data-testid={MANAGE_API_TOKENS_APP_CREATE}
                icon={IconNames.PLUS}
                intent={Intent.PRIMARY}
                minimal
                onClick={() =>
                  dispatch(showCreateApiTokenDialog({ appId: app.id }))
                }
                outlined
              >
                {copy.components.manageApiTokens.voiceboxAppCard.add}
              </Button>
            </div>
          </div>
        </div>

        <hr className="divider" />
        {apiTokens.length ? (
          <ApiTokensTable apiTokens={apiTokens} appId={app.id} />
        ) : (
          <p>{copy.components.manageApiTokens.voiceboxAppCard.empty}</p>
        )}
      </div>
    </Card>
  );
};
