import { Intent } from '@blueprintjs/core';
import * as React from 'react';
import {
  VoiceboxConversationContext,
  VoiceboxSchemaContext,
  VoiceboxSettingsContext,
  useVoiceboxSettingsValue,
} from 'vet-bones/components/molecules/Voicebox';
import { VoiceboxSchema } from 'vet-bones/constants/voicebox';
import { noop } from 'vet-bones/utils';
import { useDidValueChange } from 'vet-bones/utils/hooks';
import { usePortalVoicebox, useStaticVoicebox } from 'vet-bones/utils/voicebox';

import { useAppDispatch } from 'src/ui/app/hooks';
import { queueMessage } from 'src/ui/features/notifications';
import * as copy from 'src/ui/templates/copy';

interface VoiceboxContextProps {
  children: React.ReactNode;
  initialConversationId?: string;
  newConversationKey?: string;
  onConversationChange?: (conversationId: string) => void;
  schema: VoiceboxSchema;
}

export const PortalVoiceboxContext: React.VFC<VoiceboxContextProps> = ({
  children,
  initialConversationId,
  newConversationKey,
  onConversationChange,
  schema,
}) => {
  const dispatch = useAppDispatch();

  const onFeedbackError = React.useCallback(
    /* istanbul ignore next */
    (message: string) => {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message,
        })
      );
    },
    [dispatch]
  );

  const onDownloadCsvError = React.useCallback(
    /* istanbul ignore next */
    (err: string) => {
      dispatch(
        queueMessage({
          intent: Intent.DANGER,
          message: copy.components.voicebox.downloadCsvError(err),
        })
      );
    },
    [dispatch]
  );

  const onDownloadCsvSuccess = React.useCallback(
    /* istanbul ignore next */
    (_, fileName: string) => {
      dispatch(
        queueMessage({
          intent: Intent.SUCCESS,
          message: copy.components.voicebox.downloadCsvSuccess(fileName),
        })
      );
    },
    [dispatch]
  );

  const conversation = usePortalVoicebox({
    id: newConversationKey,
    initialConversationId,
    onDownloadCsvError,
    onDownloadCsvSuccess,
    onFeedbackError,
    onMessageError: noop,
    schema,
  });
  const { conversationId } = conversation;
  const didConversationIdChange = useDidValueChange(conversationId);
  React.useEffect(() => {
    if (didConversationIdChange) {
      onConversationChange?.(conversationId);
    }
  }, [conversationId, didConversationIdChange, onConversationChange]);
  const voiceboxSettings = useVoiceboxSettingsValue({
    id: newConversationKey,
    conversation,
    schema,
  });

  return (
    <VoiceboxSchemaContext.Provider value={schema}>
      <VoiceboxConversationContext.Provider value={conversation}>
        <VoiceboxSettingsContext.Provider value={voiceboxSettings}>
          {children}
        </VoiceboxSettingsContext.Provider>
      </VoiceboxConversationContext.Provider>
    </VoiceboxSchemaContext.Provider>
  );
};

export const StaticVoiceboxContext: React.VFC<VoiceboxContextProps> = ({
  children,
  initialConversationId,
  newConversationKey,
  onConversationChange,
  schema,
}) => {
  const conversation = useStaticVoicebox({
    id: newConversationKey,
    initialConversationId,
    cloudResponseConfig: {},
    schema,
  });
  const { conversationId } = conversation;
  const didConversationIdChange = useDidValueChange(conversationId);
  React.useEffect(() => {
    if (didConversationIdChange) {
      onConversationChange?.(conversationId);
    }
  }, [conversationId, didConversationIdChange, onConversationChange]);
  const voiceboxSettings = useVoiceboxSettingsValue({
    id: newConversationKey,
    conversation,
    schema,
  });

  return (
    <VoiceboxSchemaContext.Provider value={schema}>
      <VoiceboxConversationContext.Provider value={conversation}>
        <VoiceboxSettingsContext.Provider value={voiceboxSettings}>
          {children}
        </VoiceboxSettingsContext.Provider>
      </VoiceboxConversationContext.Provider>
    </VoiceboxSchemaContext.Provider>
  );
};
