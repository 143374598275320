import {
  Alignment,
  AnchorButton,
  Button,
  Colors,
  Icon,
  IconSize,
  Navbar,
  NavbarDivider,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { History } from 'history';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { ConnectionButton } from 'src/ui/components/ConnectionButton';
import { LearnMenu } from 'src/ui/components/LearnMenu';
import {
  NAV_CONTAINER,
  NAV_EMPLOYEE_HOME_BUTTON,
  NAV_EXPAND_LEFT_NAVBAR_BUTTON,
  NAV_HELP_MENU,
} from 'src/ui/constants/testIds';
import { toggledLeftNavbar } from 'src/ui/features/ui';
import { useLeftNavbarTabFromRoute } from 'src/ui/hooks/leftNavbarTabFromRoute';
import { isEmployee } from 'src/ui/selectors/isEmployee';
import { copy } from 'src/ui/templates/copy';
import logo from 'src/ui/templates/images/Stardog_Cloud_Logo_Light.svg';
import { colors } from 'src/ui/templates/styles/colors';

export const AppNavBar: React.VFC = () => {
  const dispatch = useAppDispatch();
  const isEmployeeSelector = isEmployee();
  const isEmployeeLoggedIn = useAppSelector(isEmployeeSelector);

  const history: History = useHistory();
  const leftNavbarTab = useLeftNavbarTabFromRoute();

  return (
    <Navbar className="sd-app-navbar" data-testid={NAV_CONTAINER}>
      <Navbar.Group align={Alignment.LEFT}>
        {leftNavbarTab ? (
          <Button
            minimal
            data-testid={NAV_EXPAND_LEFT_NAVBAR_BUTTON}
            icon={<Icon color={Colors.WHITE} icon={IconNames.LIST} />}
            onClick={() => dispatch(toggledLeftNavbar())}
          />
        ) : null}
        <Link className="sd-app-navbar__logo" to="/">
          <img src={logo} alt={copy.appNavBar.altLogo} />
        </Link>
      </Navbar.Group>
      {isEmployeeLoggedIn && (
        <Navbar.Group align={Alignment.LEFT}>
          <Tooltip2 content="Employee Home" position="bottom-left">
            <AnchorButton
              data-testid={NAV_EMPLOYEE_HOME_BUTTON}
              id="nav-employee-home"
              icon={
                <Icon
                  icon={IconNames.ID_NUMBER}
                  iconSize={IconSize.LARGE}
                  color={colors.connected}
                />
              }
              minimal
              onClick={() => history.push('/internal')}
            />
          </Tooltip2>
        </Navbar.Group>
      )}
      <Navbar.Group align={Alignment.RIGHT}>
        <Popover2 content={<LearnMenu />} position={Position.BOTTOM}>
          <AnchorButton
            data-testid={NAV_HELP_MENU}
            large
            minimal
            text="Learn Stardog"
            id="nav-help-menu"
          />
        </Popover2>
        <NavbarDivider />
        <ConnectionButton />
      </Navbar.Group>
    </Navbar>
  );
};
