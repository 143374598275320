import { Classes } from '@blueprintjs/core';
import * as React from 'react';

import { copy } from 'src/ui/templates/copy';
import geoaxisButton from 'src/ui/templates/images/geoaxis.svg';
import { doGeoaxisRedirect } from 'src/ui/utils/window';

/**
 * This uses just a plain button as we use an image to display the actual
 * button. We don't want this to be styled by blueprintjs.
 */
export const GeoaxisLink: React.VFC = () => {
  return (
    <button
      type="button"
      onClick={doGeoaxisRedirect}
      className={`${Classes.BUTTON} oauth`}
    >
      <img src={geoaxisButton} alt={copy.geoaxis.label} />
      {copy.geoaxis.label}
    </button>
  );
};
